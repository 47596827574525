/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, CardHeader, Col, Button, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
// import GoogleMapReact from 'google-map-react';
import FaceDetectionCamera from "components/FaceDetectionCamera";
import { getAddressMap } from "utils/getMapAddress";
import MobileHeader1 from "components/Headers/MobileHeader1";
import Halaman404 from "views/404";

export default function IndexCheckin(props) {
  const token = localStorage.token;
  const warehouseId = localStorage.warehouse;
  const username = localStorage.username;
  let history = useHistory();
  const [imageFile, setImageFile] = useState(null);
  const [alert, setAlert] = useState(null);
  const [long, setLong] = useState(0);
  const [lat, setLat] = useState(0);
  const [address, setAddress] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      ceklangtut();
    }, 2000);
    return () => clearInterval(interval);
  }, []);


  // useEffect(() => {
  //   if (imageFile) {
  //     handleSubmit();
  //     setImageFile(null); // Reset setelah pengiriman untuk mencegah pemanggilan ganda
  //   }
  // }, [imageFile]);

  useEffect(() => {
    if (imageFile && !isSubmitting) {
      setIsSubmitting(true);
      handleSubmit();
      // console.log("sudah masuk cok");
      
    }
  }, [imageFile]);

  const ceklangtut = async () => {
    navigator.geolocation.getCurrentPosition(async function (position) {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
      const pointAddress = await getAddressMap(position.coords.latitude, position.coords.longitude);
      setAddress(pointAddress.results[2].formatted_address);
    });
  };

  // const handleSubmit = () => {
  //  setIsSpinner(true);
  //   EditData();
  //   // setTimeout(EditData, 2000);
  // };

  const handleSubmit = () => {
    if (!imageFile) {
      setGagalAlert("Posisikan wajah anda di depan kamera selama 2 detik.");
      setIsSubmitting(false);
      return;
    }
    setIsSpinner(true);
    EditData();
    // setTimeout(EditData, 2000);
  };

  const EditData = () => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const myjson = JSON.stringify({
      warehouse_id: parseInt(warehouseId),
      username: username,
      latitude: lat,
      longitude: long,
      address: address,
    });
    let data = new FormData();
    data.append("body", myjson);
    data.append("datang", imageFile);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/check-in`, data, { headers })
      .then(function (response) {
        setIsSpinner(false);
        history.push("/mobile/staff-absen/sukses")
      })
      .catch(function (error) {
        setGagalAlert(error.response.data.message);
      });
  };

  const setGagalAlert = (message) => {
    setIsSpinner(false);
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={message}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const ButtonBack = () => {
    history.push("/mobile/staff-absen");
  };

  const handleImageCapture = (files) => {
    const base64 = files;
    fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], `absensi.jpeg`);
        setImageFile(file);
      });
  };

  // const googleMapsApiKey = `${process.env.REACT_APP_MAP}`;

  // const AnyReactComponent = ({ text }) =>
  //   <div style={{
  //     padding: '5px 5px',
  //     display: 'inline-flex',
  //     textAlign: 'center',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     borderRadius: '100%',
  //     transform: 'translate(-50%, -50%)',
  //     width: 40, height: 40
  //   }}>
  //     <img src="https://i.ibb.co/XYf5GGc/masuk.png" width="40px" />
  //   </div>;

  // const defaultProps = {
  //   center: {
  //     lng: long,
  //     lat: lat
  //   },
  //   zoom: 19
  // };

  return (
    <>
      {alert}
      <div className="d-none d-lg-block">
        <Halaman404 />
      </div>
      <div className="d-lg-none d-xl-none">
        {isSpinner ? (
          <Container className="mt--10 pb-10 login-container">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <div className="d-flex flex-column align-items-center d-lg-none d-xl-none">
                  <img
                    src={require("assets/img/theme/loadingmobile1.gif").default}
                    style={{
                      textAlign: "center",
                      height: "12rem",
                      width: "12rem",
                      paddingTop: "1rem",
                    }}
                    alt="loading"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            <MobileHeader1 name="" parentName="Master" />
            <Container className="mt--6" fluid>
              <Row md="12">
                <Col md="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ textAlign: 'left' }}>
                          <Button color="link" onClick={ButtonBack}>
                            <i className="fa fa-arrow-circle-left fa-2x" />
                          </Button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Absensi Masuk</b>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <center><h6>{address}</h6></center><br />
                      <FaceDetectionCamera setImageFile={handleImageCapture} />
                    </CardBody>
                    {/* <div style={{ height: '30vh', width: '100%' }}>
                      {
                        lat != null &&
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: googleMapsApiKey, libraries: ['places', 'geometry', 'drawing', 'visualization'] }}
                          center={defaultProps.center}
                          zoom={14}
                        >
                          <AnyReactComponent
                            lng={long}
                            lat={lat}
                            center={{ lat: lat, lng: long }}
                            radius={500}
                          />
                        </GoogleMapReact>
                      }
                    </div> */}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </>
  );
}
