/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState} from "react";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// core components
// import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
// import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
// import AdminMobile from "components/Navbars/AdminMobile";

function MobileLayout() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const [alert, setAlert] = useState("")
  const mainContentRef = React.useRef(null);
  const username = localStorage.username
  const token = localStorage.token

  React.useEffect(() => {
    cekuseraccess()
    cektoken()
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
 

  React.useEffect(() => {
    // cekuseraccess()
    // cektoken()
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/mobile") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const cekuseraccess = async () => {
		let data = {
			username: username,
		  }
		await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/access-checking`, data, {
			headers: {
			  Authorization: `Bearer ${token}`,
			}
		  })
		  .then(function () {
		  })
		  .catch(function (error) {
			setGagalAlert()
			if(error.response.data.status === 400){
				cekUsername();
			}
		  });
	};

	const setGagalAlert = () => {
		setAlert(
		  <SweetAlert
		  danger
		  showConfirm
		  title="Mohon Cek Jaringan Anda. Refresh Halaman ini segera. Terima Kasih"
		  onCancel={hideAlert}
		  onConfirm={hideAlert}
		  />
		);
		};
		
		
		  const hideAlert = () => {
			setAlert(null);
		  };


	const cektoken =() => {
		let data = {
			username : username,
		  };
			axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/token-check`, data, {
			headers: {
			  Authorization: `Bearer ${token}`,
			}
		  })
			.then((data) => {

			})
			.catch(function (error) {
					cekUsername();
			  });
	};

	const cekUsername = () => {
		let data = {
			username : username,
		  };
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/users/logout`, data, { headers: { Authorization: `Bearer ${token}` } })
			.then(function () {
				window.location.href = "/auth/login";
				localStorage.clear();
				// localStorage.removeItem("token");
				// localStorage.removeItem("allinfo");
				// localStorage.removeItem("authority");
				// localStorage.removeItem("email");
				// localStorage.removeItem("name");
				// localStorage.removeItem("token");
				// localStorage.removeItem("usercode");
				// localStorage.removeItem("userId");
				// localStorage.removeItem("username");
				// localStorage.removeItem("usernametoken");
				// localStorage.removeItem("warehouse");
				// localStorage.removeItem("warehouseId");
			})
			.catch(function (error) {
				console.log(error);
			});
		};

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-hidden")) {
      document.body.classList.remove("g-sidenav-hidden");
      document.body.classList.add("g-sidenav-pinned");
    } else {
      document.body.classList.add("g-sidenav-hidden");
      document.body.classList.remove("g-sidenav-pinned");
    }
    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
	{alert}
      <div className="main-content" ref={mainContentRef}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/" to="/mobile/staff-absen" />
        </Switch>
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default MobileLayout;
