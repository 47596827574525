/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
    Badge,
	Card,
	CardBody,
	Row,
	CardHeader,
	Col,
    CardTitle,
	Container,
} from "reactstrap";
import axios from "axios";
import SimpleHeaderMobile from "components/Headers/SimpleHeaderMobile"
import PerfectScrollbar from "react-perfect-scrollbar";

export default function LogAksesMobileProfile() {
    const token = localStorage.token;
	const username = localStorage.username;
    const warehousecok= localStorage.warehouse;
    const [allPenawaranPo, setAllPenawaranPo] = useState([]);

    useEffect(() => {
        getPenawaranPo()
      }, [])

    const getPenawaranPo = () => {
        let filter = { 
            page: 1, per_page: 100 , 
            warehouse_id : parseInt(warehousecok), 
            username: username
        };
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/log-access/page`, data, {
            headers,
          })
          .then((data) => {
            setAllPenawaranPo(data.data.response);
          })
          .catch(function (error) {
      });
    }

	return (
		<>
			<div>
                <SimpleHeaderMobile to="/mobile/profile" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Row md="12">
									<Col md="12">
										<Card className="bg-secondary shadow">
											<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h2>Log Akses</h2>
                                                </div>
											</CardHeader>
                                            <CardBody style={{ height: '60vh'}}>
                                                <PerfectScrollbar>
                                                    <div style={{ height: '60vh', overflowY: "auto"}}>
                                                            {allPenawaranPo.map((v) => (
                                                            <Card style={{ marginBottom: "0rem"}}>
                                                            <CardBody>
                                                                <Row>
                                                                <div className="col">
                                                                    <CardTitle className="h5 text-uppercase text-muted mb-0 text-black">
                                                                    {v.time_access}&nbsp;{v.status_kerja === 1 ? (
                                                                    <Badge color="success">{v.keterangan}</Badge>
                                                                ) : v.status_kerja === 2 ? (
                                                                    <Badge color="danger">{v.keterangan}</Badge>
                                                                ): <Badge color="warning">{v.keterangan}</Badge>}
                                                                    </CardTitle>
                                                                    <span className="h5 font-weight-bold mb-0 text-black">
                                                                    Device : {v.device_access}
                                                                    </span>
                                                                </div>
                                                                </Row>
                                                            </CardBody>
                                                            </Card>
                                                            ))
                                                        } 
                                                    </div>
                                                </PerfectScrollbar>
                                            </CardBody>
										</Card>
									</Col>
								</Row>
						</div>
					</Row>
				</Container>
			</div>
		</>
	);
}
