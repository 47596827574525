/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Row, 
    Col, 
    CardBody,
    Button ,
    CardImg,
    CardHeader,
    Table,
    Container, 
    Media,
    Modal,ModalHeader, ModalBody, 
} from 'reactstrap';
import axios from 'axios';
import MobileHeader1 from 'components/Headers/MobileHeader1';

const ProfileMobile = () => {
    const token = localStorage.token;
    const userid = localStorage.uic;
    const username = localStorage.username;
    const [bergabung, setBergabung] = useState("")
    const [modal6, setModal6] = useState(false);
    const toggless = () => setModal6(!modal6);
    const [name,setName] = useState("");
    const [picture,setPicture] = useState("");
    const [jabatan,setJabatan] = useState("");

  const successCallback = (position) => {
    // console.log(position);
  };
  
  const errorCallback = (error) => {
    // console.log(error);
  };
  
  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  useEffect(() => {
    getByUser()
    // getPenawaranPo()
  }, [])


  const getByUser = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/users/profil/${userid}`,
        { headers }
      )
      .then((data) => {
        setName(data.data.response.name);
        setPicture(data.data.response.karyawan_image);
        setJabatan(data.data.response.jabatan);
        setBergabung(data.data.response.join_date);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


function cekUsername() {
    axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/users/logout`, { username }, { headers: { Authorization: `Bearer ${token}` } })
        .then(function (res) {
            window.location.href = "/auth/login";
            localStorage.clear();

            // localStorage.removeItem("token");
            // localStorage.removeItem("allinfo");
            // localStorage.removeItem("authority");
            // localStorage.removeItem("email");
            // localStorage.removeItem("name");
            // localStorage.removeItem("token");
            // localStorage.removeItem("usercode");
            // localStorage.removeItem("userId");
            // localStorage.removeItem("username");
            // localStorage.removeItem("usernametoken");
            // localStorage.removeItem("warehouse");
            // localStorage.removeItem("warehouseId");
        })
        .catch(function (error) {
            console.log(error);
        });
}

async function handleLogout() {
    cekUsername();
}

  return (
    <div>
    <MobileHeader1 />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="card-profile">
                <CardImg
                    alt="..."
                    src={require("assets/img/theme/img-1-1000x600.jpg").default}
                    top
                />
                <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                        {picture === "" ?(
                        <img
                          alt=""
                          className="rounded-circle"
                          src={"https://i.imgur.com/Hzxpp28.png"}
                        />
                        ):(
                        <img
                            alt=""
                            className="rounded-circle"
                            src={picture}
                        />
                        )}
                        </a>
                    </div>
                    </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-5 pt-md-4 pb-0 pb-md-4">
                    <div className="d-flex justify-content-between">
                    <Button
                    hidden
                        className="mr-4"
                        color="info"
                        href="/mobile/profile/new-password"
                        size="sm"
                    >
                        Password
                    </Button>
                    <Button
                    hidden
                        className="float-right"
                        color="danger"
                        onClick={() => handleLogout()}
                        size="sm"
                    >
                        Logout
                    </Button>
                    </div>
                </CardHeader>
                <br></br>
                <CardBody className="pt-0">
                    <div className="text-center">
                    <h5 className="h3">
                        {name}
                    </h5>
                    </div>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center">
                  <div>
                    <span className="description">Bergabung</span><br></br>
                    <span className="description"><b>{bergabung}</b></span>
                  </div>
                  <div>
                    <span className="description">Jabatan</span><br></br>
                    <span className="description"><b>{jabatan}</b></span>
                  </div>
                </div>
              </div>
                </CardBody>
            </Card>
            <Table className="align-items-center bg-white" responsive>
          
              <tbody>
              
                <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                        <i className="fa fa-unlock-alt fa-2x" aria-hidden="true"></i>
                      <a
                        class="nav-link"
                        href="/mobile/profile/new-password"
                      >
                      <Media>
                        <span className="mb-0 text-sm">
                          Password 
                        </span>
                      </Media>
                      </a>
                    </Media>
                  </th>
                </tr>
                <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                      
                        <i class="fa fa-history fa-2x" aria-hidden="true"></i>
                      <a
                        class="nav-link"
                        href="/mobile/log-akses"
                      >
                      <Media>
                        <span className="mb-0 text-sm">
                          Log Akses 
                        </span>
                      </Media>
                      </a>
                    </Media>
                  </th>
                </tr>
                <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                        <i className="fas fa-sign-out-alt fa-2x" />
                      <a
                        class="nav-link"
                        onClick={() => handleLogout()}
                      >
                      <Media>
                        <span className="mb-0 text-sm">
                          Keluar 
                        </span>
                      </Media>
                      </a>
                    </Media>
                  </th>
                </tr>
              </tbody>
            </Table>
            <br></br>
          </div>
        </Row>
      </Container>
        <Modal isOpen={modal6} toggle={toggless}>
          <ModalHeader toggle={toggless} align="center"></ModalHeader>
          <ModalBody align="center">
          <font size="5"><b>Coming Soon</b></font><br/><br/>
          <i class="fa fa-cog fa-5x fa-spin" aria-hidden="true"></i><br/><br/>
          <Button color="secondary" onClick={toggless}>
            Cancel
          </Button>
          </ModalBody>
        </Modal>
        {/* navigasi bottom */}
        {/* d-md-none d-lg-none d-xl-none */}
        <nav class="navbar bg-white navbar-expand fixed-bottom d-md-none d-lg-none d-xl-none">
            <ul class="navbar-nav nav-justified w-100">
                <li class="nav-item">
                    <a href="/mobile/staff-absen" class="nav-link text-center">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-house" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                            <path fill-rule="evenodd"
                                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                        </svg>
                        <span class="small d-block">Home</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#" class="nav-link text-center">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-person" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                        </svg>
                        <span class="small d-block">Akun</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
  );
}

export default ProfileMobile;